import React from 'react'
import useStyles from './Style'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRouter } from 'next/router'

const Blobs = () => {
  const { classes } = useStyles()
  const theme = useTheme()
  const router = useRouter()
  const paths = ['/fundraise', '/articles']
  const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <div className={classes.blobWrapper}>
      <svg
        className="leftBlob"
        width={greaterThanMd ? '798' : '600'}
        height="669"
        viewBox="0 0 798 669"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f_1456_11968)">
          <path
            d="M725.524 366.221C658.202 532.908 468.5 613.459 301.813 546.137C135.126 478.815 54.575 289.114 121.897 122.427C189.219 -44.26 324.098 137.766 490.785 205.088C657.472 272.41 792.846 199.534 725.524 366.221Z"
            fill="url(#paint0_radial_1456_11968)"
            fillOpacity={paths.includes(router.pathname) ? '0.29' : '0.4'}
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1456_11968"
            x="44.125"
            y="0.046875"
            width="753.051"
            height="623.863"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="27"
              result="effect1_foregroundBlur_1456_11968"
            />
          </filter>
          <radialGradient
            id="paint0_radial_1456_11968"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(138.521 260.176) rotate(6.34867) scale(548.832 548.832)"
          >
            <stop stopColor="#E726BF" />
            <stop offset="1" stopColor="#FDCEAD" />
          </radialGradient>
        </defs>
      </svg>

      <svg
        className="rightBlob"
        width={greaterThanMd ? '522' : '400'}
        height="413"
        viewBox="0 0 522 413"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f_1456_11972)">
          <path
            d="M463.409 111.223C485.571 223.438 412.569 332.372 300.355 354.535C188.14 376.697 79.2053 303.695 57.0428 191.48C34.8804 79.2654 170.079 129.269 282.294 107.106C394.509 84.9438 441.246 -0.991917 463.409 111.223Z"
            fill="url(#paint0_radial_1456_11972)"
            fillOpacity={paths.includes(router.pathname) ? '0.29' : '0.4'}
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1456_11972"
            x="0.632812"
            y="0.761719"
            width="520.742"
            height="411.736"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="27"
              result="effect1_foregroundBlur_1456_11972"
            />
          </filter>
          <radialGradient
            id="paint0_radial_1456_11972"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(113.844 259.063) rotate(-26.8164) scale(349.208 349.208)"
          >
            <stop stopColor="#E726BF" />
            <stop offset="1" stopColor="#FDCEAD" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  )
}

export default Blobs
