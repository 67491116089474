import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'

const useStyles = makeStyles()(() => ({
  popularText: {
    fontWeight: 700,
    lineHeight: '19px',
    fontStyle: 'normal',
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '14px',
    marginRight: '20px',
    textTransform: 'uppercase',
    fontFamily: nunito_sans.style.fontFamily,
  },
  tagContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

export default useStyles
