import React from 'react'
import { Typography, Box } from '@mui/material'
import useStyles from './Style'
// import homeHeart from 'src/assets/images/home/homeHeart.png'
// import Image from 'next/image'
import BgImage from 'src/assets/images/home/hero-Text-Fill-1.jpg'
interface typoProps {
  content: string
  variant:
    | 'inherit'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | undefined
  style?: React.CSSProperties
  type: 'primary' | 'secondary' | 'tertiary' | 'subHeading'
  className?: string
  icon?: boolean
  zenMode?: boolean
}

const CustomTypo: React.FC<typoProps> = ({
  content,
  variant,
  style,
  type,
  className,
  icon,
  zenMode,
}) => {
  const { classes } = useStyles()
  const homeFontStyle = {
    backgroundImage: `url(${!zenMode ? BgImage.src : ''})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: !zenMode ? 'transparent' : '',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '130% 150%',
    backgroundClip: 'text',
    maxWidth: '1050px',
    width: '100%',
    margin: 'auto',
    marginBottom: '1rem',
    overflow: 'visible',
  }
  return (
    <Box>
      {/* <Box className={classes.mobShow}>
        {type == 'primary' && icon ? (
          <Image
            className={classes.heartIconSm}
            src={homeHeart}
            alt="hear icon"
          />
        ) : (
          ''
        )}
      </Box> */}
      <Box>
        <Typography
          variant={variant}
          gutterBottom
          style={
            icon && type === 'primary'
              ? { ...style, ...homeFontStyle }
              : { ...style }
          }
          className={
            type === 'primary'
              ? `${classes.articleHeading}  ${className}`
              : type === 'secondary'
              ? `${classes.articleText} ${className}`
              : type === 'subHeading'
              ? `${classes.subHeading} ${className}`
              : `${classes.articleSecondary} ${className}`
          }
        >
          {type == 'primary' && icon ? (
            <div>
              {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
              <h1
                dangerouslySetInnerHTML={{ __html: content }}
                className={`${classes.articleHeading}  ${className}`}
                style={{ padding: 0, margin: 0 }}
              />
              {/* {!zenMode && (
                <Image
                  className={`${classes.heartIconLg}`}
                  src={homeHeart}
                  alt="heart icon"
                />
              )} */}
            </div>
          ) : (
            content
          )}
        </Typography>
      </Box>
    </Box>
  )
}

export default CustomTypo
