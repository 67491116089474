/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import useStyles from './Style'
import algoliasearch from 'algoliasearch/lite'
import { createAutocomplete } from '@algolia/autocomplete-core'
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import { getCookie } from 'cookies-next'
import { useDispatch } from 'react-redux'
import { Box, Container, Grid, InputAdornment, Typography } from '@mui/material'
import CustomTypo from './CustomTypo/CustomTypo'
import { RoundTextField } from 'src/components/shared/atoms'
import TagContainer from './TagContainer/TagContainer'
import { SearchIcon } from 'src/assets/svgs'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Nunito_Sans, Lato } from '@next/font/google'
import { contentType } from 'src/types/components/shared/atom'
import { useRouter } from 'next/router'
import Blobs from 'src/components/shared/modules/blobs'
import HeroHeaderBg from 'src/components/shared/modules/lottiePlayer/heroHeaderBg'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store/store'
import Modal from 'src/components/shared/modules/modal'
import { selectedDropDownValueHandler } from 'src/store/reducer/selectedDropDownValueSlice'
import HelplineCard from 'src/components/shared/modules/HelpLinesCard'
import { useSensitiveWordsQuery } from 'src/store/ApiCall/sensitiveWordAPI'
import type { SensitiveWordsDataType } from 'src/types/store'
import brp from 'src/assets/images/brp2d.png'
import Image from 'next/image'

const nunito_sans = Nunito_Sans({
  weight: ['400', '700', '800'],
  subsets: ['latin'],
})

const lato = Lato({
  weight: '400',
  subsets: ['latin'],
})

interface HeroHeaderProps {
  heroContent: contentType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bgPath?: string
  showBrp?: boolean
  homeIcon?: boolean
  placeHolder?: string
  endAdornment?: boolean
  searchField?: boolean
  poweredText?: string
  populartopicsDisabled?: boolean
  pageName?: string
}

const HeroHeader: React.FC<HeroHeaderProps> = ({
  heroContent,
  homeIcon,
  placeHolder,
  endAdornment,
  searchField,
  poweredText,
  populartopicsDisabled,
  showBrp,

  pageName,
}) => {
  const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP
    ? process.env.NEXT_PUBLIC_ALGOLIA_APP
    : ''
  const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
    ? process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
    : ''
  const searchClient = algoliasearch(appId, apiKey)

  const { classes } = useStyles()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [autocompleteState, setAutocompleteState] = React.useState<any>({})
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [sensitiveWords, setSensitiveWords] = React.useState<string[]>([]) // TODO: UPDATE searchArticles type

  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const { data: sensitiveApiRes } = useSensitiveWordsQuery(undefined)
  const autocomplete = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state)
        },
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: 'articles',
              getItemInputValue({ item }) {
                return item.query as string
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName:
                        getCookie('lang') === 'En'
                          ? 'search_articles'
                          : 'mx_search_articles',
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                        clickAnalytics: true,
                      },
                    },
                  ],
                })
              },
              getItemUrl({ item }) {
                return item.url as string
              },
            },
          ]
        },
      }),
    []
  )

  React.useEffect(() => {
    // call api here and set the sensitive word list
    if (sensitiveApiRes && sensitiveApiRes.data.length) {
      setSensitiveWords(
        sensitiveApiRes.data.map(
          (i: SensitiveWordsDataType) => i.attributes.word
        )
      )
    } else {
      setSensitiveWords([
        'hostage',
        'abuse',
        'depress',
        'depression',
        'suicide',
      ])
    }
  }, [sensitiveApiRes])
  const { content } = heroContent
  const dispatch = useDispatch()
  const [dropDownOpen, setDropDownOpen] = React.useState('collapsed')
  const zenMode = useSelector((state: RootState) => state.zenModeState.zenMode)
  // const selectedDropDownValue =  useSelector((state: RootState) => state.selectedDropDownValueState.selectedDropDownValue)

  // const [query, setQuery] = React.useState('')

  const router = useRouter()

  const [age, setAge] = React.useState('all')
  const [lang, setLang] = React.useState<string>('En')

  React.useEffect(() => {
    if (
      typeof window === 'object' &&
      (window.location.href.includes('mx') ||
        window.location.href.includes('quit') ||
        window.location.href.includes('quiat'))
    ) {
      localStorage.setItem('lang', 'Es')
      setLang('Es')
    } else {
      localStorage.setItem('lang', 'En')
      setLang('En')
    }
  }, [typeof window === 'object' ? window.location.href : null])
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string)
    dispatch(selectedDropDownValueHandler(event.target.value))
  }

  React.useEffect(() => {
    dispatch(selectedDropDownValueHandler('all'))
  }, [])

  const ContentUI: JSX.Element[] | '' | 0 = Object.keys(content)
    ? Object.keys(content).length &&
      Object.keys(content).map((curr, index) => (
        <CustomTypo
          zenMode={zenMode}
          key={index}
          icon={homeIcon}
          className={
            content[curr].type === 'tertiary'
              ? lato.className
              : nunito_sans.className
          }
          content={content[curr].content}
          variant={content[curr].variant}
          type={content[curr].type}
        />
      ))
    : ''

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    // setQuery(e.target.value)
  }

  const enterKeyHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      router.push(`/search?search=${inputRef?.current?.value}`)
      return
    } else {
      if (sensitiveWords && inputRef?.current?.value) {
        setTimeout(() => {
          const value = inputRef?.current?.value
          if (value && value.length) {
            for (const i of value.split(' ')) {
              if (sensitiveWords.includes(i)) {
                setModalOpen(!modalOpen)
                setSensitiveWords(sensitiveWords.filter((e) => e !== i))
              }
            }
          }
        }, 0)
      }
    }
    return
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        {pageName && !zenMode && <HeroHeaderBg pageName={pageName} />}

        <Box className={`${classes.rootContainer} ${nunito_sans.className}`}>
          {!pageName && !zenMode && router.pathname !== '/about-us' && (
            <Blobs />
          )}
          <Container className={classes.contentContainer} disableGutters>
            {ContentUI}
            {showBrp && getCookie('lang') !== 'En' ? (
              <>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{ marginTop: '10px' }}
                >
                  <Typography variant="h6" sx={{ marginRight: '10px' }}>
                    IMPULSADO POR
                  </Typography>
                  {/* <BRPLogo fillColor="black" /> */}
                  <Image src={brp} alt="brp" width="50" height="50" />
                </Grid>
              </>
            ) : (
              ''
            )}
            {searchField && (
              <Box
                className={classes.popularContainer}
                sx={{ marginBottom: 10 }}
              >
                <RoundTextField
                  variant="outlined"
                  fullWidth
                  placeholder={endAdornment ? '' : placeHolder}
                  className={
                    dropDownOpen === 'collapsed'
                      ? `${classes.signupField} ${classes.signupFieldCollapsed} `
                      : `${classes.signupField} ${classes.signupFieldExpanded}`
                  }
                  onChange={searchHandler}
                  onKeyDown={enterKeyHandler}
                  InputProps={{
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <Box className={classes.inputAdornmentStart}>
                            <SearchIcon />
                            {endAdornment && (
                              <div
                                className="aa-Autocomplete"
                                {...autocomplete.getRootProps({})}
                              >
                                <form
                                  {...(autocomplete.getFormProps({
                                    inputElement: inputRef.current,
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  }) as any)}
                                >
                                  <input
                                    className={classes.aaInput}
                                    ref={inputRef}
                                    {...(autocomplete.getInputProps({
                                      placeholder: 'Ask us anything...',
                                      inputElement: inputRef.current,

                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    }) as any)}
                                    onKeyDown={enterKeyHandler}
                                  />
                                </form>
                              </div>
                            )}
                          </Box>
                        </InputAdornment>

                        <Grid style={{ position: 'absolute' }}></Grid>
                      </>
                    ),
                    endAdornment: endAdornment && (
                      <InputAdornment position="end">
                        <>
                          <Typography className={classes.aiIcon}>
                            <span>🤖</span> {poweredText}
                          </Typography>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onOpen={() => setDropDownOpen('expanded')}
                            onClose={() => setDropDownOpen('collapsed')}
                            className={
                              dropDownOpen === 'expanded'
                                ? classes.dropDownBtn
                                : classes.dropDownClose
                            }
                            value={age}
                            label="Age"
                            onChange={handleChange}
                            MenuProps={{
                              classes: { paper: classes.dropdownStyle },
                            }}
                          >
                            <MenuItem value={'all'}>
                              {lang === 'En' ? 'All' : 'Todos'}
                            </MenuItem>
                            <MenuItem value={'articles'}>
                              {lang === 'En' ? 'Article' : 'Artículos'}
                            </MenuItem>
                            <MenuItem value={'videos'}>
                              {lang === 'En' ? 'Videos' : 'Videos'}
                            </MenuItem>
                            {lang === 'En' && (
                              <MenuItem value={'research-papers'}>
                                {lang === 'En' ? 'Research papers' : ''}
                              </MenuItem>
                            )}
                            {lang === 'En' && (
                              <MenuItem value={'community-threads'}>
                                {lang === 'En' ? 'Community Threads' : ''}
                              </MenuItem>
                            )}
                          </Select>
                        </>
                      </InputAdornment>
                    ),
                  }}
                />
                {autocompleteState.isOpen &&
                autocompleteState.collections.length ? (
                  <div
                    className={classes.aaPanel}
                    // {...autocomplete.getPanelProps({})}
                  >
                    {autocompleteState.isOpen &&
                      autocompleteState.collections.map(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (collection: any, index: any) => {
                          const { items } = collection

                          return (
                            <div key={`source-${index}`} className="aa-Source">
                              {items.length > 0 && (
                                <ul
                                  className={classes.aaList}
                                  {...autocomplete.getListProps()}
                                >
                                  {items.map(
                                    (item: {
                                      slug: string
                                      objectID: number
                                      title: string
                                    }) => (
                                      <li
                                        onClick={() =>
                                          router.push(`/${item.slug}`)
                                        }
                                        key={item.objectID}
                                        className={classes.aaItem}
                                      >
                                        {item.title}
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </div>
                          )
                        }
                      )}
                  </div>
                ) : (
                  ''
                )}
              </Box>
            )}

            {searchField && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                mt={-5.4}
              >
                {!populartopicsDisabled && <TagContainer />}
              </Box>
            )}
          </Container>
        </Box>
      </div>
      {modalOpen ? (
        <Modal closeHandler={() => setModalOpen(!modalOpen)}>
          <Grid
            container
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <HelplineCard onClickHandler={() => setModalOpen(!modalOpen)} />
          </Grid>
        </Modal>
      ) : null}
    </>
  )
}

export default HeroHeader
