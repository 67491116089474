import { nunito_sans } from '@styles/fonts'
import { getCookie } from 'cookies-next'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  mobShow: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  mobHide: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  articleHeading: {
    fontWeight: 800,
    fontSize: '5.25rem',
    lineHeight: '5.1875rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3rem',
      lineHeight: '3.325rem',
    },
    '@media (max-width:300px)': {
      fontSize: '2.3rem',
      lineHeight: '3.325rem',
    },
  },
  articleText: {
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: '1.705rem',
    letterSpacing: '0.4rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.36rem',
    },
  },
  articleSecondary: {
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    opacity: '0.7',

    [theme.breakpoints.down('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1.125rem',
      lineHeight: '1.36rem',
    },
  },
  subHeading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontSize: '36px',
    lineHeight: '83px',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginTop: '-20px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '27.28px',
    },
  },
  heartIconLg: {
    width: '4.5rem',
    height: '4.5rem',
    display: 'inline-block',
    position: 'absolute',
    paddingTop: '3px',
    transform: 'translateY(-113%)',
    marginLeft: getCookie('lang') === 'En' ? '8.5rem' : '13.5rem',
    '@media (max-width:1030px)': {
      transform: 'translateY(0)',
      marginLeft: '0',
      position: 'relative',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  heartIconSm: {
    width: '4.1875rem',
    height: '4.1875rem',
    paddingTop: '3px',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
  },
  headingBg: {
    fontWeight: '900 !important',
    fontStyle: 'initial !important',
  },
}))

export default useStyles
