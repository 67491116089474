import { makeStyles } from 'tss-react/mui'
const useStyles = makeStyles()(() => ({
  blobWrapper: {
    svg: {
      overflowX: 'hidden',
    },
  },
}))

export default useStyles
