import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Typography, Box } from '@mui/material'
import useStyles from './Style'
import Tag from 'src/components/shared/atoms/Tag/Tag'
import { useGetPopularTopicsQuery } from 'src/store/ApiCall/topicsAPI'
import { getCookie } from 'cookies-next'

const TagContainer: React.FC = () => {
  const { data } = useGetPopularTopicsQuery()
  const { classes } = useStyles()
  const isBrowser = typeof window === 'object' ? true : false
  const lang = getCookie('lang')
  const router = useRouter()
  const [tagData, setTagData] = useState<
    {
      id: number
      topic: string
    }[]
  >()

  useEffect(() => {
    if (data) {
      setTagData(data)
    }
  }, [data])

  const TagsUI =
    tagData &&
    tagData.length &&
    tagData.map((el, index) => (
      <Tag
        id={index}
        key={index}
        content={el.topic}
        style={{ background: 'white' }}
        onClick={() => {
          if (el.topic.includes('Mental Health')) {
            router.push(`/${'Mental-Health-Support-Hub'}`)
          } else {
            router.push(`/${el.topic + '-Support-Hub'}`)
          }
        }}
      />
    ))
  return (
    <Box
      sx={{
        display: { md: 'flex', sm: 'block', xs: 'block' },
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'start',
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        className={classes.popularText}
        sx={{ paddingBottom: { md: '0', sm: '10px', xs: '10px' } }}
      >
        {isBrowser && lang === 'En' ? 'POPULAR TOPICS:' : 'Temas populares:'}
      </Typography>
      <Box className={classes.tagContainer}>{TagsUI}</Box>
    </Box>
  )
}

export default TagContainer
